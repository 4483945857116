
<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('PdfGenerationList')}}</div>
            </div>
            <template>
             <el-link :underline="false" style="margin-left: 0px; margin-right: 5px !important; border: none !important; color: #f56c6c;" v-if="OrderId!=''?true:false">{{$t('The generation task has been submitted to the background, please wait patiently, you can leave the current page for other operations.')}}</el-link>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :page.sync="page" @current-change="currentChange">
                       <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-document-copy" type="text" size="mini" :title="$t('Copy_trace_link')" @click="CopyTraceLink(row,index)" >{{$t('Copy_trace_link')}}</el-button>
                        <el-button icon="el-icon-download" type="text" size="mini" :title="$t('Download')" v-if="row.Status==1?true:false" @click="Download(row,index)" >{{$t('Download')}}</el-button>
                        <el-button icon="el-icon-reading" type="text" size="mini" :title="$t('Enter_Preview')" v-if="row.Status==1?true:false" @click="EnterPreview(row,index)">{{$t('Enter_Preview')}}</el-button>
                    </template>
               
              </avue-crud>
            </template>
        </basic-container>
    </div>
</template>

<style> 
    .messgeStyle {
        overflow:scroll;
        overflow-x:hidden;
        width:100%;
        height:500px;
    }
</style>

<script>
import { addPackage, IsHavePackage, add,addSummaryPackage ,DownPDFPassKey,PdfGenerationList} from "@/api/orderPackage";
import { pvhApiBase } from "@/config/env";
export default {
  data() {
     return {
      downUrl: '',
      obj: {},
      tableData: [],
      page: {
            currentPage: 1,
            total: 0,
            layout: "total,pager,prev,next",
            background: true,
            pageSize: 10
           },
      Speed:"",
      OrderId: "",
      packageId: "",
      timer: "",
        typeData: [
      {
          label: this.$t('Finish'),
          value: 1
      },
      {
          label: this.$t('In_hand'),
          value: 0
      }
  ]
    }
  },
   computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 280,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                       // menu: false,
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        header: true,
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        labelPosition: 'top',
                        column: [
                            {
                                label: this.$t('PO'),
                                prop: 'Po',
                                slot:true
                            },
                           {
                                label: this.$t('Business No In'),
                                prop: 'BusinessNoIn',
                                slot:true
                            },
                            {
                                label: this.$t('FINISHED ITEM NUMBER'),
                                prop: 'Code',
                                slot:true
                            },
                              {
                                label: this.$t('COLOR'),
                                prop: 'Color',
                                slot:true
                            },
                            {
                                label: this.$t('DEGREE OF COMPLETION'),
                                prop: 'Remark',
                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'Status',
                                type: "select",
                                filterable: true,
                                dicData: this.typeData,
                             },
                            {
                                label: this.$t('OPERATOR'),
                                prop: 'CreateUserid',
                            },
                               {
                                label: this.$t('CREATTIME'),
                                prop: 'CreateTime',
                                type: "date",
                                format: "yyyy-MM-dd HH:mm:ss",
                                valueFormat: "yyyy-MM-dd HH:mm:ss",
                                order: 1,
                            },
                            {
                                label: this.$t('PAGES'),
                                prop: 'PDFPages',
                            },
                           {
                                label: this.$t('FILE SIZE')+"(MB)",
                                prop: 'Size',
                            },
                        ]
                    }
                }
            }
        },
  created() {
    this.init()
  },
  watch: {
    $route(to, from) {
      this.init()
    },
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {   
   init() {
      //新增文件包
      this.OrderId = this.$route.query.OrderId;
      this.Speed = this.$route.query.Speed;
      if(this.OrderId!="")
      {
      this.add();
      this.addSummary();
      }
      this.getList();
    },
    //显示数据
    getList() {
      PdfGenerationList(this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.OrderPackages;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro);
                })
    },
    //进入预览
     EnterPreview(row, index) {
      var OrderDetailId =row.POInfoDetail.Id;
      this.$router.push({ path: '/pvh_operation/review', query: { OrderId: OrderDetailId } });
            },
    //下载附件
      Download(row, index){
        //获取redis 
        var OrderDetailId =row.POInfoDetail.Id;
        var po_new =row.POInfo.Po;
        var IndexNumber_new = row.POInfoDetail.IndexNumber;
        DownPDFPassKey(OrderDetailId).then(res => {
            var passKey = res.data;
            // 调用子组件的下载方法
            if (po_new != undefined) {
                var pattern = new RegExp("[`~!@#$%^&-+=\\?\"|,;'\\[\\]·~！@#￥%……&*（）+=\\{\\}\\|《》？：“”【】、；‘'，。\\、\\-<>]"); //[]内输入你要过滤的字符
                var rs = "";
                for (var i = 0; i < po_new.length; i++) {
                    rs += po_new.substr(i, 1).replace(pattern, '');
                }
                po_new = rs;
            }
            else {
                po_new = "";
            }
            var fileName = this.isFinish + "GI_TR_" + po_new + "_" + IndexNumber_new + ".pdf";
            //新增操作记录
            this.packageId=row.Id;
            this.addLog(4);
            this.downUrl = pvhApiBase + "Tracing/GetPdfPreviewFileStream?packageId=" + this.loadId + "&fileName=" + fileName + "&passKey=" + passKey;
            //打开新页面
            window.open(this.downUrl, '_blank');
        })
    },
    //复制追溯链接
    CopyTraceLink(row, index) {
      var OrderDetailId =row.POInfoDetail.Id;
      var sysInNo =row.POInfoDetail.BusinessNoIn;
      var s_href=location.href.split('#');
      var copy_s=s_href[0]+"#/pvh_operation/orderFracing?ID="+OrderDetailId+"&TracingNo="+sysInNo;
      this.copyText(copy_s);
      this.$message({
          message: this.$t('OperationSuccess'),
          type: 'success'
        });
            },
    copyText(text) {
      var textarea = document.createElement("input");
      document.body.appendChild(textarea);
      textarea.value = text;
      textarea.focus();
      if (textarea.setSelectionRange)
        textarea.setSelectionRange(0, textarea.value.length);
      else
        textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },     
    currentChange(val) {
                this.page.currentPage = val;
                this.getList();
            },//搜索
    addSummary(){
           let paramrow = {
                      id: "",
                      orderId: this.OrderId,
                      path: "Pending",
                      size: 0,
                      remark: this.Speed,
                      status: 0,
                      createUserid: '',
                      corporationId: '',
                      pDFType: "5",
                  };
                  addSummaryPackage(paramrow).then(res => {
                      this.loadSummaryId = res.data.result
                  }).catch((erro) => {
                      console.log(erro)
                  });
    },
    add() {
      let paramrow = {
        id: "",
        orderId: this.OrderId,
        path: "Pending",
        size: 0,
        remark: this.Speed,
        status: 0,
        createUserid: '',
        corporationId: '',
        pDFType: "1",
      };
      addPackage(paramrow).then(res => {
        this.packageId = res.data.result

      }).catch((erro) => {
        console.log(erro)
      });
      //读取进度
      this.startProgress();
    },
    startProgress() {
      this.timer = setInterval(this.getList, 120000); // 2分钟;
    },
    addLog(type) {
      let paramrow = {
        orderPackageId: this.packageId,
        type: type,
        remark: "",
        status: 0,
        createUserid: '',
        corporationId: ''
      };
      add(paramrow).then(() => {
      }).catch((erro) => {
        console.log(erro)
      });
    },
  }
}
</script>
