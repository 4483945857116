var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("PdfGenerationList")))]
            )
          ]),
          [
            (_vm.OrderId != ""
            ? true
            : false)
              ? _c(
                  "el-link",
                  {
                    staticStyle: {
                      "margin-left": "0px",
                      "margin-right": "5px !important",
                      border: "none !important",
                      color: "#f56c6c"
                    },
                    attrs: { underline: false }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "The generation task has been submitted to the background, please wait patiently, you can leave the current page for other operations."
                        )
                      )
                    )
                  ]
                )
              : _vm._e(),
            _c("avue-crud", {
              ref: "crud",
              attrs: {
                option: _vm.setData.tableOpt,
                data: _vm.tableData,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-document-copy",
                            type: "text",
                            size: "mini",
                            title: _vm.$t("Copy_trace_link")
                          },
                          on: {
                            click: function($event) {
                              return _vm.CopyTraceLink(row, index)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Copy_trace_link")))]
                      ),
                      (row.Status == 1
                      ? true
                      : false)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-download",
                                type: "text",
                                size: "mini",
                                title: _vm.$t("Download")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.Download(row, index)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Download")))]
                          )
                        : _vm._e(),
                      (row.Status == 1
                      ? true
                      : false)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-reading",
                                type: "text",
                                size: "mini",
                                title: _vm.$t("Enter_Preview")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.EnterPreview(row, index)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Enter_Preview")))]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.obj,
                callback: function($$v) {
                  _vm.obj = $$v
                },
                expression: "obj"
              }
            })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }